<template>
  <div>
    <b-card>
      <!-- form -->
      <validation-observer ref="formImport">
        <b-form>
          <b-row>
            <b-col sm="3">
              <b-form-group label="Arquivo de Importação" label-for="competencia">
                <validation-provider #default="{ validate, errors }" name="Arquivo de importação" rules="required">
                  <b-form-file
                    class="mr-1"
                    ref="fileInput"
                    accept=".txt"
                    placeholder="Escolha um arquivo"
                    @change="handleFileChange($event) || validate($event)"
                    drop-placeholder="Deixe seu arquivo aqui..."
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-2 w-100 alinhamentoInputImportMargemBtn"
                @click.prevent="authESalve"
              >
                Importar Arquivo
              </b-button>
            </b-col>
            <b-col md="2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="secondary"
                class="mt-2 w-100 alinhamentoInputImportMargemBtn"
                @click.prevent="downloadLayout"
              >
                Layout de Importação
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

      <!-- INICIO DO VERIFICA SENHA -->
      <b-modal id="modal-auth-senha" ref="modal-auth-senha" centered size="sm" title="Autenticação" hide-footer>
        <AutenticaSenha @autenticarEvent="autenticarMethod" />
      </b-modal>
    </b-card>

    <b-card v-if="itemsAlterados.length > 0" title="Registros Importados">
      <b-row class="rounded" style="overflow: hidden">
        <b-table
          class="position-relative rounded"
          :per-page="pesquisar.perPage"
          :items="itemsAlterados"
          :fields="fields"
          :current-page="pesquisar.currentPage"
        >
          <template #cell(statusImportacao)=""> Importado com sucesso! </template>
          <template #cell(margem30)="row">
            {{ formatarValor(row.item.margem30) }}
          </template>
        </b-table>
      </b-row>
    </b-card>

    <b-card v-if="itemsNaoAlterados.length > 0" title="Falhas na Importação">
      <b-row class="rounded" style="overflow: hidden">
        <b-table
          class="position-relative rounded"
          :per-page="pesquisar.perPage"
          :items="itemsNaoAlterados"
          :fields="fields"
          :current-page="pesquisar.currentPage"
        >
          <template #cell(margem30)="row">
            {{ formatarValor(row.item.margem30) }}
          </template>

          <template #cell(statusImportacao)=""> Matricula não encontrada </template>
        </b-table>
      </b-row>
    </b-card>
  </div>
</template>

<script>
  //validacao
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarValor, isLetter } from '@/libs/utils'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import NaoAutorizadoModal from '@/views/error/nao-autorizado-modal.vue'
  import { formatarParaNumber } from '@/libs/utils'

  export default {
    components: {
      ValidationObserver,
      ValidationProvider,
      required,
      AutenticaSenha,
      NaoAutorizadoModal,
      formatarValor,
    },
    directives: {
      Ripple,
    },
    props: {
      generalData: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        optionsLocal: JSON.parse(JSON.stringify(this.generalData)),
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        items: {},
        isLetter,
        formatarValor,
        exercicioOpcoes: [
          { value: '', text: 'Selecione' },
          { value: '2023', text: '2023' },
          { value: '2022', text: '2022' },
          { value: '2021', text: '2021' },
          { value: '2020', text: '2020' },
          { value: '2019', text: '2019' },
          { value: '2018', text: '2018' },
          { value: '2017', text: '2017' },
          { value: '2016', text: '2016' },
          { value: '2015', text: '2015' },
        ],
        competenciaOpcoes: [
          { value: '', text: 'Selecione' },
          { value: '1', text: 'Janeiro' },
          { value: '2', text: 'Fevereiro' },
          { value: '3', text: 'Março' },
          { value: '4', text: 'Abril' },
          { value: '5', text: 'Maio' },
          { value: '6', text: 'Junho' },
          { value: '7', text: 'Julho' },
          { value: '8', text: 'Agosto' },
          { value: '9', text: 'Setembro' },
          { value: '10', text: 'Outubro' },
          { value: '11', text: 'Novembro' },
          { value: '12', text: 'Dezembro' },
        ],
        acesso: {},

        // Table
        itemsAlterados: [],
        itemsNaoAlterados: [],
        pesquisar: {
          perPage: 10,
          currentPage: 1,
        },
        fields: [
          { key: 'matricula', label: 'Matrícula', sortable: true },
          { key: 'nomeServidor', label: 'Nome do Servidor', sortable: true },
          { key: 'margem30', label: 'Valor da Margem', sortable: true },
          { key: 'dataFimContrato', label: 'Data Fim do Contrato', sortable: true },
          { key: 'orgao', label: 'Órgão', sortable: true },
          { key: 'statusImportacao', label: 'Status Importação', sortable: true },
        ],
      }
    },
    mounted() {
      this.items.exercicio = ''
      this.items.competencia = ''
      this.$refs.formImport.reset()

      // this.itemsAlterados = [
      //   {
      //     "matricula": "0000001403",
      //     "cpf": "34470573191",
      //     "nomeServidor": "JOAO PAULO DA SILVA",
      //     "estabelecimento": "001",
      //     "orgao": "001",
      //     "margem30": "0000850.25",
      //     "margem10": "0000100.11",
      //     "dataNascimento": "01/01/1970",
      //     "dataAdmissao": "01/01/1999",
      //     "dataFimContrato": "01/01/1999",
      //     "regimeTrabalho": "Estatutário",
      //     "localTrabalho": "Secretaria de Educação",
      //     "carteiraIdentidade": "00000MG11222333"
      //   }
      // ];

      // this.itemsNaoAlterados = [
      //   {
      //     "matricula": "0000001403",
      //     "cpf": "34470573191",
      //     "nomeServidor": "JOAO PAULO DA SILVA",
      //     "estabelecimento": "001",
      //     "orgao": "001",
      //     "margem30": "0000850.25",
      //     "margem10": "0000100.11",
      //     "dataNascimento": "01/01/1970",
      //     "dataAdmissao": "01/01/1999",
      //     "dataFimContrato": "01/01/1999",
      //     "regimeTrabalho": "Estatutário",
      //     "localTrabalho": "Secretaria de Educação",
      //     "carteiraIdentidade": "00000MG11222333"
      //   }
      // ];
    },
    methods: {
      authESalve() {
        this.$refs.formImport.validate().then((success) => {
          if (success) {
            this.items.mode = 'authESalve'
            this.$refs['modal-auth-senha'].show()
          }
        })
        this.autenticarMethod(true)
      },
      autenticarMethod(data) {
        if (data) {
          this.$refs['modal-auth-senha'].hide()

          if (this.items.mode == 'authESalve') {
            this.lerArquivo()
          }
        }
      },
      lerArquivo() {
        const file = this.$refs.fileInput.files[0]
        if (!file) this.$message.error('Informe o arquivo de importação')

        const reader = new FileReader()
        reader.onload = () => {
          const dadosArquivo = reader.result
          const seguradoes = this.lerArquivoImportacao(dadosArquivo)
          this.enviaDadosParaRegistro(seguradoes)
          this.$message.success('O arquivo foi importado!')
        }
        reader.readAsText(file)
      },
      lerArquivoImportacao(dadosArquivo) {
        const dados = dadosArquivo
        const linhas = dados.split('\n')
        const seguradoes = []

        linhas.forEach((linha) => {
          const matricula = linha.substring(0, 10).trim()
          const cpf = linha.substring(10, 21).trim()
          const nomeServidor = linha.substring(21, 71).trim()
          const estabelecimento = linha.substring(71, 74).trim()
          const orgao = linha.substring(74, 77).trim()
          const margem30 = linha.substring(77, 87).trim()
          const margem10 = linha.substring(87, 97).trim()
          const dataNascimento = linha.substring(97, 105).trim()
          const dataAdmissao = linha.substring(105, 113).trim()
          const dataFimContrato = linha.substring(113, 121).trim()
          const regimeTrabalho = linha.substring(121, 161).trim()
          const localTrabalho = linha.substring(161, 201).trim()
          const carteiraIdentidade = linha.substring(201, 216).trim()

          seguradoes.push({
            matricula,
            cpf,
            nomeServidor,
            estabelecimento,
            orgao,
            margem30,
            margem10,
            dataNascimento,
            dataAdmissao,
            dataFimContrato,
            regimeTrabalho,
            localTrabalho,
            carteiraIdentidade,
          })
        })

        return seguradoes
      },
      handleFileChange(event) {},
      enviaDadosParaRegistro(seguradoes) {
        let parametrosEnvio = {
          institutoId: this.userData.institutoSelecionado,
          listaSegurados: seguradoes,
        }
        useJwt
          .post('cadastro/tomador/importaMargemSegurado', parametrosEnvio)
          .then((response) => {
            let dadosAlterados = response.data.dadosAlterados
            let dadosNaoAlterados = response.data.dadosNaoAlterados

            if (dadosAlterados.length > 0) {
              dadosAlterados.forEach((alterado) => {
                alterado.matricula = formatarParaNumber(alterado.matricula)
                alterado.dataFimContrato = String(alterado.dataFimContrato).replace(/^(\d{2})(\d{2})(\d{4})$/, '$1/$2/$3')
              })
            }

            if (dadosNaoAlterados.length > 0) {
              dadosNaoAlterados.forEach((naoAlterado) => {
                naoAlterado.dataFimContrato = String(naoAlterado.dataFimContrato).replace(/^(\d{2})(\d{2})(\d{4})$/, '$1/$2/$3')
                naoAlterado.dataFimContrato = String(naoAlterado.dataFimContrato).replace(/^(\d{2})(\d{2})(\d{4})$/, '$1/$2/$3')
              })
            }

            this.itemsAlterados = dadosAlterados
            this.itemsNaoAlterados = dadosNaoAlterados
          })
          .catch((error) => {
            console.error('error', error)
          })
      },
      downloadLayout() {
        let layoutImportacaoMargem = `# Leiaute de importação de margem consignável

# Mapeamento de Campos (JSON -> Tabela SQL Server)
# "cpf" -> "CPF do tomador"
# "matricula" -> "Matricula do cadastro de segurado vinculado ao tomador"
# "remuneracao" -> "Remuneracao do segurado"
# "margemUtilizada" -> "Margem  que já está utilizada"
# "margemDisponivel" -> "Valor atualizado da margem e principal informação da importação"

# Exemplo de Dados de Entrada (JSON):
# [
#   {
#     "cpf": "206.225.831-87",
#     "matricula": 111555,
#     "remuneracao": 3516.04,
#     "margemUtilizada": 0.00,
#     "margemDisponivel": 1230.61
#   }
# ]`

        var element = document.createElement('a')
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(layoutImportacaoMargem))
        element.setAttribute('download', 'Layout Importacao Margem Consignavel.txt')

        element.style.display = 'none'
        document.body.appendChild(element)

        element.click()

        document.body.removeChild(element)
      },
    },
  }
</script>

<style>
  .alinhamentoInputImportMargemBtn {
    margin-top: 1.6rem !important;
  }
</style>
